import _ from "lodash";
import React, { Fragment, useEffect, useRef, useState } from "react";
import DataCollections from "../../components/data-collections/data-collections";
import { DataCollectionsState } from "./data-collections-state";
import * as collectionService from '../../services/data-collection.service';
import { AppEnum } from "../../constants/app-enum";
import { useNavigate } from "react-router-dom";
import MuiDialog from "../../../src/shared/mui-dialog/mui-dialog.js";
import { getRemainingBalance } from "../../services/license-service.js";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { truncateString } from "../../shared/code-challanges/code-challanges.js";
import NativeDataVisualizerController from "./NativeDataVisualizer/native-data-visualizer-controller.js";
import { setRemainingFeatureBalance } from "../../store/slices/as-Slice";
import { getItem } from "../../shared/local-storage-handler/local-storage-handler";
import DataVisualizerController from "./DataVisualizer/data-visualizer-controller.js";

export const ALL = "All"
const DataCollectionsController = () => {

    // user permission state
    const [state, setState] = useState(new DataCollectionsState());
    const [showDataVisualizer, setShowDataVisualizer] = useState(false);

    const navigate = useNavigate();
    const searchInputRef = useRef(null);
    const dispatch = useDispatch();

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [collectionUId, setCollectionUId] = useState('');
    const [selectedConnectionValue, setSelectedConnectionValue] = useState([ALL, ALL]);
    const [dataCollectionListRefresh, setDataCollectionListRefresh] = useState(0);
    const [responseRecieved, setResponseReceived] = useState(false);
    const [showNativeDataVisualizer, setShowNativeDataVisualizer] = useState(false);

    useEffect(() => {
        dispatch(setRemainingFeatureBalance(null));

        getUserDataCollection()
        getRemainingBalance(AppEnum.FeatureType.COLLECTION_LIMIT).then((res) => {
            if (res?.data?.featureCD != null) {
                dispatch(setRemainingFeatureBalance(res.data.remainingFeatureUnit));
            }
        })
    }, [dataCollectionListRefresh])

    // it fetches data collection
    const getUserDataCollection = () => {

        collectionService.getDataCollection()
            .then((response) => {
                if (response?.hasError)
                    setState((prevState) => { return { ...prevState, errorMessage: response?.errorMessage, } });

                else if (response?.data) {
                    setUniqueFilteredDataCollections(AppEnum.CollectionFilterId.groupName, response?.data);

                    const storedValues = localStorage.getItem("DataCollectionListFilter");
                    if (storedValues) {
                        setState((prevState) => {
                            return {
                                ...prevState, dataCollections: response?.data,
                            }
                        });

                        const storedFilterValues = JSON.parse(storedValues);
                        setSelectedConnectionValue(storedFilterValues);

                        storedFilterValues?.forEach((filterName, index) => {
                            let filterId = index == 0 ? AppEnum.CollectionFilterId.connection : AppEnum.CollectionFilterId.groupName
                            let menuList;
                            let selectedMenuItem;
                            if (state?.filterHeader.length > 0) {
                                menuList = filterId == AppEnum.CollectionFilterId.connection ? connectionMenuItems(state?.filterHeader?.[0], response?.data) : connectionMenuItems(state?.filterHeader?.[1], response?.data)
                                if (filterId == AppEnum.CollectionFilterId.connection) {
                                    selectedMenuItem = menuList?.find(coll => coll?.connection?.name == storedFilterValues?.[0]);
                                }
                                else if (filterId == AppEnum.CollectionFilterId.groupName) {
                                    selectedMenuItem = menuList?.find(coll => coll?.groupName == storedFilterValues?.[1]);
                                }
                            }

                            filterDataCollectionsByMenu(selectedMenuItem ? selectedMenuItem : menuList, filterName, response?.data, filterId);
                        });
                    }
                    else {
                        setState((prevState) => {
                            return {
                                ...prevState, dataCollections: response?.data,
                                filteredDataCollections: response?.data,
                                filteredDataCollectionsAsSource: response?.data,
                            }
                        });

                        //Calling Filter Collection fuction to first time render
                        filterDataCollectionsByMenu(response?.data[0], ALL, response?.data);
                    }

                    setResponseReceived(true);
                }
            })
    }


    // it sets the shrink state to true to handle the mui outlined textbox
    const onFocusSearchBoxHandler = () => {

        setState((prevState) => { return { ...prevState, isShrink: true } });
    }

    // it sets the shrink state to false to handle the mui outlined textbox
    const onBlurSearchBoxHandler = (event) => {

        if (event.target.value.length === 0)
            setState((prevState) => { return { ...prevState, isShrink: false } });
    }

    // searches the data collection by the key
    const onChangeSearchCollectionHandler = (searchedKey) => {
        resetErrorMessages();

        let filteredDataCollections = [];
        let searchedCollectionList = [];

        if (searchedKey) {
            filteredDataCollections = state.filteredDataCollections;
            searchedCollectionList = [];

            _.forEach(filteredDataCollections, function (collection) {
                if (_.includes(collection?.name?.toLowerCase().trim(), searchedKey?.toLowerCase().trim()))
                    searchedCollectionList.push(collection);
            })


            if (state.collectionAsDataSource !== ALL)
                searchedCollectionList = state.collectionAsDataSource === 'Yes' ? searchedCollectionList.filter(i => i.isCollectionAsDataSource)
                    : searchedCollectionList.filter(i => !i.isCollectionAsDataSource);
        }
        else
            searchInputRef.current.value = '';

        setState((prevState) => {
            return {
                ...prevState,
                searchedCollectionList: searchedKey ? searchedCollectionList : []
            }
        });
    }

    // it filters the data collections choosen by the tab options (uses lodash functions)
    const filterDataCollectionsByTab = (data) => {
        resetErrorMessages();

        let prevFilterList = state.filterHeader;

        _.forEach(prevFilterList, function (filter) {

            if (data.filterId === filter.filterId)
                _.set(filter, 'isSelected', true);
            else
                _.set(filter, 'isSelected', false);
        })

        let preDataCollections = state.dataCollections;

        _.forEach(preDataCollections, function (collection) {
            _.set(collection, 'isSelected', false);
        })

        setUniqueFilteredDataCollections(data.filterId, preDataCollections);

        setState((prevState) => {
            return {
                ...prevState,
                //  filterHeader: prevFilterList,
                selectedFilterId: data.filterId,
                //   filteredDataCollections: prevState.dataCollections,
                dataCollections: preDataCollections
            }
        });
    }

    // it filters the data collection and all the collections will be unique
    const setUniqueFilteredDataCollections = (filterId, preDataCollections) => {
        resetErrorMessages();
        var uniqueDataCollections = [];

        // unique data collection by group name
        if (filterId === AppEnum.CollectionFilterId.groupName) {
            uniqueDataCollections = _.uniqBy(preDataCollections, function (collection) {
                return collection?.groupName;
            });
        }

        // unique data collection by connection name
        if (filterId === AppEnum.CollectionFilterId.connection) {
            uniqueDataCollections = _.uniqBy(preDataCollections, function (collection) {
                return collection?.connection?.name;
            });
        }

        // unique data collection by collection as source
        if (filterId === AppEnum.CollectionFilterId.collectionAsSource) {
            uniqueDataCollections = _.filter(preDataCollections, function (collection) {
                return collection?.isCollectionAsDataSource
            });
        }

        setState((prevState) => { return { ...prevState, uniqueFilteredDataCollections: uniqueDataCollections, } });
    }

    // it filters the data collections choosen by the menu options (uses lodash functions)
    const filterDataCollectionsByMenu = (menu, filterName, dataCollection, selectedFilterId) => {
        resetErrorMessages();

        let preDataCollections = dataCollection ? dataCollection : state.dataCollections;
        const storedValues = localStorage.getItem("DataCollectionListFilter");
        const storedValuesParsed = storedValues ? JSON.parse(storedValues) : null;
        _.forEach(preDataCollections, function (collection) {

            if (menu.id === collection.id)
                _.set(collection, 'isSelected', true);
            else
                _.set(collection, 'isSelected', false);
        })

        let filteredDataCollections;

        if (selectedFilterId === AppEnum.CollectionFilterId.groupName) {
            filteredDataCollections = _.filter(dataCollection ? dataCollection : state.dataCollections, function (collection) {
                return collection?.groupName === menu?.groupName;
            });

            if (storedValuesParsed) {
                if (storedValuesParsed?.[0] != ALL) {
                    let filteredConnection = preDataCollections?.find(coll => coll?.connection?.name == storedValuesParsed?.[0]);

                    if (filterName != ALL) {
                        filteredDataCollections = _.filter(filteredDataCollections, function (collection) {
                            return collection?.connection?.id == filteredConnection?.connection?.id;
                        });
                    }
                    else {
                        filteredDataCollections = _.filter(dataCollection ? dataCollection : state.dataCollections, function (collection) {
                            return collection?.connection?.id === filteredConnection?.connection?.id;
                        });
                    }
                }
                else if (filterName == ALL) {
                    filteredDataCollections = _.filter(dataCollection ? dataCollection : state.dataCollections, function (collection) {
                        return collection;
                    });
                }
            }
        }

        if (selectedFilterId === AppEnum.CollectionFilterId.connection) {
            filteredDataCollections = _.filter(dataCollection ? dataCollection : state.dataCollections, function (collection) {
                return collection?.connection?.id === menu?.connection?.id;
            });

            if (storedValuesParsed) {
                if (storedValuesParsed?.[1] != ALL) {
                    if (filterName != ALL) {
                        filteredDataCollections = _.filter(filteredDataCollections, function (collection) {
                            return collection?.groupName === storedValuesParsed?.[1];
                        });

                        if (filteredDataCollections?.length == 0) {
                            let newFilters = [storedValuesParsed[0], ALL];
                            setSelectedConnectionValue(newFilters);
                            localStorage.setItem("DataCollectionListFilter", JSON.stringify(newFilters));
                            filteredDataCollections = _.filter(dataCollection ? dataCollection : state.dataCollections, function (collection) {
                                return collection?.connection?.id === menu?.connection?.id;
                            });
                        }
                    }
                    else {
                        filteredDataCollections = _.filter(dataCollection ? dataCollection : state.dataCollections, function (collection) {
                            return collection?.groupName === storedValuesParsed?.[1];
                        });
                    }
                }
                else if (filterName == ALL) {
                    filteredDataCollections = _.filter(dataCollection ? dataCollection : state.dataCollections, function (collection) {
                        return collection;
                    });
                }
            }
        }

        if (selectedFilterId === AppEnum.CollectionFilterId.collectionAsSource)
            filteredDataCollections = _.filter(dataCollection ? dataCollection : state.dataCollections, function (collection) {
                return (collection?.isCollectionAsDataSource);
            });

        if (filterName == ALL && !selectedFilterId)
            filteredDataCollections = _.filter(dataCollection ? dataCollection : state.dataCollections, function (collection) {
                setSelectedConnectionValue([ALL, ALL])
                return collection;
            });



        setState((prevState) => {
            return {
                ...prevState, filterMenuName: filterName, filteredDataCollections: filteredDataCollections,
                dataCollections: preDataCollections
            }
        });
        setFilteredDataCollectionsAsSource(filteredDataCollections, state.collectionAsDataSource);

    }

    // it opens the data collection to update the collection details
    const editDataCollection = (event, collection) => {

        if (event)
            event.stopPropagation();

        navigate(`/data-collections/update-collection/${collection.uId}`, {
            state: collection
        })
    }

    // it is a method to handle state of dialog popup
    const handlePopUpState = () => {
        setState((prevState) => {
            return {
                ...prevState, isShowPopUp: false,
                collectionUId: ""
            }
        });
    }

    // it deletes the selected data collection
    const deleteDataCollection = (event, collection) => {
        resetErrorMessages();
        if (event)
            event?.stopPropagation();
        closeCollectionMenuOption();

        if (collection)
            setState((prevState) => {
                return {
                    ...prevState, isShowPopUp: true,
                    collectionUId: collection.uId
                }
            });

        if (state.isShowPopUp) {
            handlePopUpState();

            collectionService.deleteDataCollection(state.collectionUId)
                .then((response) => {

                    if (!response?.hasError) {
                        let filteredDataCollections = state.filteredDataCollections;
                        let searchedCollectionList = state.searchedCollectionList;
                        let dataCollections = state.dataCollections;

                        dataCollections.splice(dataCollections.findIndex(a => a?.uId === state.collectionUId), 1);

                        let filteredDataIndex = filteredDataCollections.findIndex(a => a?.uId === state.collectionUId);
                        if (filteredDataIndex !== -1)
                            filteredDataCollections.splice(filteredDataIndex, 1);

                        if (searchedCollectionList?.length > 0) {
                            let searchedCollectionIndex = searchedCollectionList.findIndex(a => a?.uId === state.collectionUId);
                            if (searchedCollectionIndex !== -1)
                                searchedCollectionList.splice(searchedCollectionIndex, 1);
                        }

                        setState((prevState) => {
                            return {
                                ...prevState, filteredDataCollections: filteredDataCollections,
                                searchedCollectionList: searchedCollectionList, dataCollections: dataCollections
                            }
                        });
                        // setDataCollectionListRefresh(Math.random());
                        toast.success("Collection deleted successfully.");
                    }
                    else {
                        setState((prevState) => { return { ...prevState, errorMessage: response?.errorMessage, } });
                        toast.error(response?.errorMessage);
                    }
                })
        }
    }

    // it closes the popup 
    const closeDialog = () => {
        resetErrorMessages();
        setState((prevState) => {
            return {
                ...prevState, isShowPopUp: false
            }
        });
        closeCollectionMenuOption();
    }

    // it opens or closes the user menu options (edit and delete)
    const openCollectionMenuOption = (event, isOpen, index) => {

        resetErrorMessages();
        if (event)
            event.stopPropagation();

        setState((prevState) => {
            return {
                ...prevState, isCollectionMenuOpen: isOpen,
                collectionEnchorEl: event.target,
                selectedCollectionIndex: index
            }
        });
    }

    // it opens or closes the user menu options (edit and delete)
    const closeCollectionMenuOption = (event) => {

        resetErrorMessages();
        if (event)
            event.stopPropagation();

        setState((prevState) => {
            return {
                ...prevState, isCollectionMenuOpen: false,
                collectionEnchorEl: null,
                selectedCollectionIndex: null
            }
        });
    }

    // it resets the error message state
    const resetErrorMessages = () => {
        if (state.errorMessage)
            setState((prevState) => { return { ...prevState, errorMessage: null, } });
    }


    const onSelectCollectionAsSourceHandler = (selectedValue) => {
        let collectionAsDataOptions = [...state.collectionAsSourceOptions]

        _.forEach(collectionAsDataOptions, function (collection) {

            if (collection.title === selectedValue)
                _.set(collection, 'isSelected', true);
            else
                _.set(collection, 'isSelected', false);
        })

        setFilteredDataCollectionsAsSource(state.filteredDataCollections, selectedValue);

        setState((prevState) => {
            return {
                ...prevState,
                collectionAsSourceOptions: collectionAsDataOptions,
                collectionAsDataSource: selectedValue,
            }
        });
    }

    const setFilteredDataCollectionsAsSource = (filteredDataCollections, selectedValue) => {
        let filteredDataCollectionsAsSource = [];
        let searchedCollectionList = [];

        if (selectedValue === ALL)
            filteredDataCollectionsAsSource = filteredDataCollections;
        else {
            filteredDataCollectionsAsSource = selectedValue === 'Yes' ? filteredDataCollections.filter(i => i.isCollectionAsDataSource)
                : filteredDataCollections.filter(i => !i.isCollectionAsDataSource);

        }

        if (searchInputRef?.current?.value) {
            searchedCollectionList = [];

            _.forEach(filteredDataCollectionsAsSource, function (collection) {
                if (_.includes(collection?.name?.toLowerCase().trim(), searchInputRef.current.value?.toLowerCase().trim()))
                    searchedCollectionList.push(collection);
            })
        }

        setState((prevState) => {
            return {
                ...prevState,
                filteredDataCollectionsAsSource: filteredDataCollectionsAsSource, searchedCollectionList: searchedCollectionList
            }
        });
    }

    const routeToUserPermission = (event, collection) => {
        if (event)
            event.stopPropagation();

        navigate(`/data-collections/user-permissions/${collection.uId}`, {
            // state: collection
        })
    }

    const routeToCopyCollection = (event, collection) => {
        if (event)
            event.stopPropagation();

        closeCollectionMenuOption(event);

        setIsDialogOpen(!isDialogOpen);
        setCollectionUId(collection.uId);
    }

    const handleCloseDialog = () => {
        // if(event)
        //      event.stopPropagation();
        setIsDialogOpen(!isDialogOpen);
    }

    const connectionMenuItems = (data, preCollection) => {

        let preDataCollections = preCollection ? preCollection : state.dataCollections;
        let uniqueDataCollections;
        const storedValues = localStorage.getItem("DataCollectionListFilter");
        const storedPrevValues = storedValues ? JSON.parse(storedValues) : null;

        if (data.filterId === AppEnum.CollectionFilterId.groupName) {
            uniqueDataCollections = _.uniqBy(preDataCollections, function (collection) {
                return collection?.groupName;
            });

            if (storedPrevValues && storedPrevValues?.[0] != ALL) {
                uniqueDataCollections = _.filter(uniqueDataCollections, function (collection) {
                    return collection?.connection?.name == storedPrevValues?.[0];
                });
            }
        }


        // unique data collection by connection name
        if (data.filterId === AppEnum.CollectionFilterId.connection) {
            uniqueDataCollections = _.uniqBy(preDataCollections, function (collection) {
                return collection?.connection?.name;
            });
        }
        return [{ connection: { name: ALL }, groupName: ALL }, ...uniqueDataCollections];

    }

    const menuItemsForConnections = (data, filterName, filterId) => {
        return (
            <div className="collection-menu-item" >
                {(filterId === AppEnum.CollectionFilterId.connection && data?.connection?.connectionImageIcon) &&
                    <img src={`/assets/${data.connection.connectionImageIcon}`} alt="conn-icon" ></img>
                }
                <div>{truncateString(filterName, 25)}</div>
            </div>
        )
    }

    const onSelectedConnectionChange = (event) => {
        const { name, value } = event;
        const storedValues = localStorage.getItem("DataCollectionListFilter");
        let preValue = ALL;
        if (storedValues) {
            const storedPrevValues = JSON.parse(storedValues);

            preValue = name !== "Connection" ? storedPrevValues?.[0] : storedPrevValues?.[1];
        }
        const newValues = name === "Connection" ? [value, preValue] : [preValue, value];

        setSelectedConnectionValue(newValues);
        localStorage.setItem("DataCollectionListFilter", JSON.stringify(newValues));
    };

    const openDataVisualizer = (e, collection) => {
        setCollectionUId(collection.uId)

        if (collection?.templateCollectionId) {
            setShowDataVisualizer(true);
        }
        else {
            setShowNativeDataVisualizer(true);
        }

        closeCollectionMenuOption(e);
    };

    return (
        <Fragment>
            <MuiDialog
                isShowPopUp={state.isShowPopUp}
                secondaryButtonAction={closeDialog}
                primaryButtonAction={deleteDataCollection}
                closeDialog={closeDialog}
                secondaryButtonTitle="No"
                primaryButtonTitle="Yes"
                titleDescription="Are you sure you want to delete this collection?"
            />
            {showDataVisualizer && collectionUId &&
                <DataVisualizerController isForDataCollections={true}
                    closeWindowOnPopupClose={true}
                    collectionUId={collectionUId}
                    setShowDataVisualizer={setShowDataVisualizer}
                    showDataVisualizer={showDataVisualizer} />
            }
            <DataCollections
                state={state}
                onFocusSearchBoxHandler={onFocusSearchBoxHandler}
                onBlurSearchBoxHandler={onBlurSearchBoxHandler}
                onChangeSearchCollectionHandler={onChangeSearchCollectionHandler}
                filterDataCollectionsByTab={filterDataCollectionsByTab}
                filterDataCollectionsByMenu={filterDataCollectionsByMenu}
                editDataCollection={editDataCollection}
                deleteDataCollection={deleteDataCollection}
                openCollectionMenuOption={openCollectionMenuOption}
                closeCollectionMenuOption={closeCollectionMenuOption}
                searchInputRef={searchInputRef}
                onSelectCollectionAsSourceHandler={onSelectCollectionAsSourceHandler}
                routeToUserPermission={routeToUserPermission}
                routeToCopyCollection={routeToCopyCollection}
                isDialogOpen={isDialogOpen}
                handleCloseDialog={handleCloseDialog}
                collectionUId={collectionUId}
                setDataCollectionListRefresh={setDataCollectionListRefresh}
                connectionMenuItems={connectionMenuItems}
                menuItemsForConnections={menuItemsForConnections}
                onSelectedConnectionChange={onSelectedConnectionChange}
                selectedConnectionValue={selectedConnectionValue}
                responseRecieved={responseRecieved}
                openDataVisualizer={openDataVisualizer}
            />
            {showNativeDataVisualizer &&
                <NativeDataVisualizerController
                    collectionUId={collectionUId}
                    showNativeDataVisualizer={showNativeDataVisualizer}
                    setShowNativeDataVisualizer={setShowNativeDataVisualizer} />}
        </Fragment>
    );
}

export default DataCollectionsController;